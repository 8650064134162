.MuiInputLabel-root {
  font-size: 15px !important; /* Adjust the font size */
}

.header{
  display: flex;
  justify-content: space-between;
}
@media  screen and (max-width: 700px) {
    .header {
      display: block;
      text-align: center;
    }
  }

.stepper{
  display: flex;
 justify-content: space-between;
}
@media  screen and (max-width: 600px) {
    .stepper {
      display: none;
    }
  }
.Step-complete{
    padding: 20px;
    width: 25%;
    -webkit-clip-path: polygon(0% 0%, calc(100% - 20px) 0%, 100% 50%, calc(100% - 20px) 100%, 0% 100%);
    background-color: #1a75ff;
    color: white;
}

.Step-payment{
    padding: 20px;
    width: 25%;
    -webkit-clip-path: polygon(0% 0%, calc(100% - 20px) 0%, 100% 50%, calc(100% - 20px) 100%, 0% 100%);
    /* background-color: aqua; */
    color: #1a75ff;
}

.Step-details{
  padding: 20px;
  width: 25%;
  -webkit-clip-path: polygon(0% 0%, calc(100% - 20px) 0%, 100% 50%, calc(100% - 20px) 100%, 0% 100%);
  /* background-color: aqua; */
  color: #1a75ff;
}
#question-button{
 background-color:#1a75ff;
 border-radius:50%;
 padding:2px
}
.extra-details{
  margin-top: 2px;
  width:80%;
  margin-left:5%;
  font-Size:15px;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

#info-icon{
    stroke: white;
     stroke-width: 1;
      font-size:22px;
      color:grey
}
.info-div{
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        padding:5px;
        
}
#question-icon{
    color: white;
    font-size:13px
   }
.next-button{
    text-align: end;
    margin-top: 10px;
 
}
 /* Login */

 /* src/App.css */
.login-page {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.login-form {
  background-color: #f5f5f5;
  border: 1px solid #ccc;
  padding: 20px;
  text-align: center;
  border-radius: 5px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}

.login-form h2 {
  margin-bottom: 20px;
}

.login-form input {
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 3px;
}

.login-form button {
  background-color: #007bff;
  color: #fff;
  border: none;
  padding: 10px 20px;
  border-radius: 3px;
  cursor: pointer;
}

.login-form button:hover {
  background-color: #0056b3;
}

.welcome-message {
  text-align: center;
}

.react-pdf__Page__canvas {

  width: 60% !important;
  height: 80% !important;
}

.footerI{
  display: flex;
  margin-left:"37%";
 
}
@media  screen and (max-width: 600px) {
    .footerI {
      display: block;
    }
  }

  .react-datepicker-popper {
    z-index: 9999 !important;
}

.customDatePickerWidth, 
.customDatePickerWidth > div.react-datepicker-wrapper, 
.customDatePickerWidth > div > div.react-datepicker__input-container
.customDatePickerWidth > div > div.react-datepicker__input-container input {
   width: 100%;
}
